<template>
  <div class="sign">
    <div class="loginPage">
      <div class="loginTitle tc">Log in</div>
      <div>
        <sign
          :prefixIcon="prefixIcon"
          @successChange="loginSuccess"
          @getLoading="btnLoadingChange"
        >
          <el-button
            slot="signBtn"
            type="primary"
            class="loginBut"
            :loading="emailLoading"
            >{{ $t("sign") }}</el-button
          ></sign
        >
      </div>
      <div class="clearfix">
        <div class="forgetPassword fl">
          <router-link to="/forgetPassword"
            >{{ $t("forgetPassword") }}？
          </router-link>
        </div>
        <div class="toReigster fr">
          <a @click="toRegister" class="registerText cursor mainColor">
            {{ $t("immedRegister") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import sign from "~/baseComponents/baseSign/sign";
import { SSstore } from "~/baseUtils/storage";
export default {
  components: {
    sign,
  },
  data() {
    return {
      emailLoading: false,
      prefixIcon: {
        email: "el-icon-message",
        emailPass: "el-icon-lock",
      },
    };
  },
  mounted() {},
  methods: {
    async loginSuccess(val) {
      let params = {
        meeting_id: this.MEETING_ID,
        user_id: this.USER_INFO.id,
        company_id: this.USER_INFO.company_id,
      };
      let result = await this.$store.dispatch(
        "baseStore/baseSign_getMeetingEnrollInfo",
        params
      );
      if (result.success) {
        this.handleUserMeetingStatus({
          notCompany: () => {
            this.$router.push("/register");
          },
          notEnroll: () => {
            this.$router.push("/register");
          },
          enrollComplete: () => {
            SSstore.set("formLogin", true);
            this.$router.push("/");
          },
        });
      }
    },
    toRegister() {
      this.$router.push("/signUp");
    },
    btnLoadingChange(val) {
      this.emailLoading = val;
    },
  },
};
</script>
<style lang="less" scoped>
.sign {
  width: 100%;
  height: calc(100vh - 62px);
  background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/overseasMeeting/signBack.png")
    100% no-repeat;
  position: relative;
  .loginPage {
    width: 368px;
    height: 380px;
    border-radius: 10px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 32px 30px;
    .loginTitle {
      font-size: 32px;
      color: #363d3b;
      font-weight: bold;
      margin-bottom: 28px;
    }
  }
  .forgetPassword {
    a {
      color: #0083f6;
    }
  }
  .loginBut {
    width: 308px;
    display: block;
    height: 40px;
    margin: 12px auto 16px;
    border: 0 none;
    font-size: 16px;
  }
}
</style>
